
import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  name: 'PageNotFound'
})
export default class PageNotFound extends Vue {

}
